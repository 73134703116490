<template>
  <v-container fluid pa-2>
    <v-row no-gutters>
      <v-col cols="12" md="12" lg="12">
        <v-stepper v-model="e1" class="elevation-0">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">Select Meters</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">Locate Meters</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <v-card flat tile>
                <v-card-title class="secondary">
                  <span>Add Meter</span>
                </v-card-title>
                <v-card-text>
                  <v-container fluid>
                    <v-row no-gutters align="center">
                      <v-col cols="12" md="3" lg="3" sm="3">
                        <p class="black--text">Gateway</p>
                      </v-col>
                      <v-col cols="12" md="8" lg="8">
                        <v-select
                          v-model="selectedGateway"
                          solo
                          chips
                          :items="gateways"
                          item-text="name"
                          item-value="serial_number"
                          label="Select Gateway"
                          required
                          return-object
                          @change="onGatewayChange()"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3" lg="3" sm="3">
                        <p class="black--text">Meters</p>
                      </v-col>
                      <v-col cols="12" md="8" lg="8">
                        <v-card tile class="elevation-0" v-if="meters.length != 0">
                          <v-chip-group multiple column active-class="primary--text">
                            <v-chip
                              label
                              color="secondary"
                              v-for="(meter) in meters"
                              :key="meter.id"
                              @click="onMeterClick(meter)"
                            >{{ meter.meta.name || meter.id}}</v-chip>
                          </v-chip-group>
                        </v-card>
                        <span v-else>no meters avaialble</span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        sm="3"
                        lg="3"
                        v-if="selectedMeters.length != 0"
                      >SelectedMeters</v-col>
                      <v-col cols="12" md="8" sm="8" lg="8" v-if="selectedMeters.length != 0">
                        <v-chip-group column text-color="white">
                          <v-chip
                            color="primary"
                            label
                            v-for="meter in selectedMeters"
                            :key="'selected'+meter.id"
                            close
                            @click:close="onMeterClose(meter)"
                          >{{ meter.meta.name || meter.id }}</v-chip>
                        </v-chip-group>
                      </v-col>
                      <!-- <v-col cols="12" lg="12" md="12" sm="12" class="justify-center">
                  <v-row no-gutters>
                    <v-col><v-btn>Select all</v-btn></v-col>
                    <v-col><v-btn>Select all</v-btn></v-col>
                      <v-col><v-btn>Select all</v-btn></v-col>
                  </v-row>
                      </v-col>-->
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <div></div>
                    <v-btn
                      depressed
                      color="green"
                      @click="selectAllMeters()"
                      :disabled="meters.length == 0"
                    >Select All Meters</v-btn>
                    <v-btn
                      depressed
                      color="orange"
                      @click="deselectAllMeters()"
                      :disabled="selectedMeters.length == 0"
                    >Deselect Gateway Meters</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="green"
                      @click="addMeters()"
                      :disabled="selectedMeters.length == 0"
                    >Add Meters</v-btn>
                    <v-btn depressed color="red" @click="cancelAddMeterView()">Cancel</v-btn>
                    <v-btn
                      depressed
                      color="green"
                      @click="toLocateMeters()"
                      :disabled="enableToLocateMetersBtn"
                    >Continue</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <locate-meters></locate-meters>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LocateMeters from "./LocateMeters";

export default {
  name: "add-meter-view",
  components: {
    LocateMeters
  },
  data() {
    return {
      e1: 1,
      selectedGateway: {},
      meters: [],
      selectedMeters: [],
      enableToLocateMetersBtn: false
    };
  },
  computed: {
    gateways() {
      return this.$store.getters["MDMStore/getterGateways"];
    }
  },
  beforeCreate() {},
  mounted() {},
  methods: {
    onGatewayChange() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/getGatewayInfo",
          gateway_serial_number: this.selectedGateway.serial_number
        })
        .then(result => {
          var meterKinds = self.$store.getters["MDMStore/getterMeterKinds"];
          var filteredMetersbyKind = result.filter(
            obj =>
              meterKinds.includes(obj.kind) == true &&
              !self.selectedMeters.find(meter => meter.id == obj.id)
          );
          self.meters = filteredMetersbyKind.map(meter =>
            Object.assign(meter, { gateway_idx: self.selectedGateway.id })
          );
        });
    },
    onMeterClick(meter) {
      this.selectedMeters.push(meter);
      this.meters.splice(this.meters.findIndex(m => m.id == meter.id), 1);
    },
    onMeterClose(meter) {
      this.selectedMeters.splice(
        this.selectedMeters.findIndex(m => m.id == meter.id),
        1
      );
      if (this.selectedGateway.id == meter.gateway_idx) {
        this.meters.push(meter);
      }
    },
    selectAllMeters() {
      var self = this;
      self.selectedMeters = [...self.selectedMeters, ...self.meters];
      self.meters = [];
    },
    deselectAllMeters() {
      var self = this;
      self.selectedMeters = [];
      this.onGatewayChange();
    },
    addMeters() {
      var self = this;
      var metersSelected = this.selectedMeters.map(function(obj) {
        var rObj = {};
        rObj["name"] = obj.meta.name || obj.id;
        rObj["serial_number"] = obj.id;
        rObj["meter_kind_idx"] = obj.kind;
        rObj["gateway_idx"] = obj.gateway_idx;
        rObj["isOffline"] = obj.hasOwnProperty('isOffline') ? obj.isOffline.toString() : "false"; 
        rObj["org_idx"] = localStorage.getItem("orgId");
        return rObj;
      });
      this.$store
        .dispatch({
          type: "MDMStore/addMeters",
          meters: metersSelected
        })
        .then(function() {
          self.enableToLocateMetersBtn = false;
          self.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Meters added successfully", status:"success"});
        });
    },
    cancelAddMeterView() {
      this.$router.push("/settings/manage-devices");
    },
    toLocateMeters() {
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/getMeters",
          orgId: localStorage.getItem("orgId")
        })
        .then(() => {
          self.e1 = 2;
        });
    }
  }
};
</script>
