<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card tile class="pa-0 mt-4" max-height="540px">
          <v-card-title class="secondary">Meters</v-card-title>
          <v-card-text class="pr-0">
            <v-radio-group
              v-model="selectedMeter"
              column
              @change="onMeterSelected()"
              style="max-height:480px; overflow-y:scroll"
            >
              <v-radio
                v-for="meter in meters"
                :key="meter.serial_number"
                :value="meter"
                :label="meter.name || meter.serial_number"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="my-4 ml-2" style="max-height:540px;">
        <!-- <v-card>
        <v-card-text class="pa-0">-->
        <GmapMap
          ref="meterMap"
          :center="center"
          :zoom="zoom"
          style="width:100%;height: 100%"
          @click="onMapClick"
        >
          <gmap-marker :icon="require('@/assets/City-Marker.svg')" :position="marker.position"></gmap-marker>
        </GmapMap>
        <!-- </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text >Add Location</v-btn>
                  </v-card-actions>
        </v-card>-->
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card tile elevation-0>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" @click="onCancel()">Cancel</v-btn>
            <v-btn color="green" @click="onSaveAndContinue()">Save & Continue</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVwaxhZM4UtfzzyxZbRtqIo8bjz-tlljE",
    v: "3.31"
  }
});
export default {
  name: "locate-meters",
  components: {},
  data() {
    return {
      zoom: 8,
      selectedMeter: {},
      marker: {},
      center: {
        lat: 10,
        lng: 13.558513
      },
    };
  },
  computed: {
    meters() {
      return this.$store.getters["MDMStore/getterMeters"];
    }
  },
  beforeCreate() {},
  mounted() {
  },
  created(){
  },
  methods: {
    onMeterSelected() {
      this.marker ={};
      if(this.selectedMeter.latitude != null && this.selectedMeter.longitude != null){
        this.center = {
        lat:this.selectedMeter.latitude,
        lng:this.selectedMeter.longitude
      }
      this.marker = {
            position:{
              lat: Number(this.selectedMeter.latitude),
              lng: Number(this.selectedMeter.longitude)
            }
          }
      }
      
    },
    onMapClick(event) {
      var self = this;
      self.marker = {};
      if (Object.keys(this.selectedMeter).length != 0) {
        this.$refs.meterMap.$mapPromise.then(() => {
          var mappedMarker = new google.maps.Marker({
            position: { lat: event.latLng.lat(), lng: event.latLng.lng() }
          });
          self.marker = {
            position: mappedMarker.position
          };
          Object.assign(self.meters.find(meter => meter.serial_number == self.selectedMeter.serial_number), {latitude:Number(event.latLng.lat().toFixed(3))},{longitude:Number(event.latLng.lng().toFixed(3))})
        });
      }
    },
    onSaveAndContinue(){
      var self = this;
      this.$store
        .dispatch({
          type: "MDMStore/addMeters",
          meters: self.meters
        })
        .then(function() {
          self.$router.push("/devices");
        });
    },
    onCancel(){
      this.$router.push("/settings/manage-devices")
    }
  }
};
</script>
